import React, { Component } from 'react';
import _ from 'lodash'

import Datatable from 'react-data-table-component'
import { Input, Button, Segment, Popup, Label } from 'semantic-ui-react'

import moment from 'moment'
import { formatNumber } from '../../utility/Helper'


const customStyles = {
    subHeader: {
      style: {
        padding: '0'
      }
    },
    headRow: {
      style: {
        backgroundColor: 'lightblue'
      }
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '13px'
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #FFFFFF',
      },
      style: {
          fontSize: '12px',
      }
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  };

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <Input label={<Button style={{minWidth: '48px'}} type="button" onClick={onClear} icon='close'></Button>} 
           labelPosition='right'
           size='small'
           type="text" placeholder="Search..."  value={filterText} onChange={onFilter} />
  )

export class DashboardTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props,
            filterText: '',
            filteredItems: [...this.props.data]
        }
        this.sendNoReply = this.props.sendNoReply
        
        this.columns = [
          {
            name: 'Borrower',
            selector: 'borrower',
            sortable: true
          },
            {
                name: 'Address',
                selector: 'address',
                sortable: true,
                grow: 2,
                cell: row => <div className='appraisals-addresses-container'>{row.properties.map((property, index) => {
                  return <p key={index}>{property?.address}</p>
                })}</div>
            },
            {
                name: 'Units',
                selector: 'units',
                sortable: true,
                cell: row => <div className='appraisals-units-container'>{
                  row.properties.map((property, index) => {
                    return <p key={index}>{property?.units}</p>
                  })}</div>
            },
            {
                name: 'Value',
                selector: 'value',
                sortable: true,
                cell: row => <div className='appraisals-value-container'>{
                  row.properties.map((property, index) => {
                    return <p key={index}>{property?.value === 0 ? '-' : `$${formatNumber(property?.value)}`}</p>
                  })}</div>
                //format: row => row.value === 0 ? '-' : `$${formatNumber(row.value)}`
            },
            {
                name: 'Date',
                selector: 'date',
                sortable: true,
                format: row => row.date ? moment.utc(row.date).format('L') : '-'
            },
            {
                name: 'Sent',
                selector: 'dateCreated',
                sortable: true,
                format: row => row.dateCreated ? moment.utc(row.dateCreated).format('L') : '-'
            },
            {
                name: 'Yes',
                selector: 'yes',
                sortable: true,
                center: true,
                grow: 0.5,
                format: row => row.yes.length === 0 ? '-' : <Popup content={
                  <>
                  {row.yes?.map((item, index) =>
                    <p key={index}>{item.firstName} {item.lastName} {`<${item.email}>`}</p>
                    )}
                  </>
                } on='click' position='left center' trigger={<Label basic content={row.yes.length} />} />
            },
            {
                name: 'No',
                selector: 'no',
                sortable: true,
                center: true,
                grow: 0.5,
                format: row => row.no.length === 0 ? '-' : <Popup content={
                  <>
                  {row.no?.map((item, index) =>
                    <p key={index}>{item.firstName} {item.lastName} {`<${item.email}>`}</p>
                    )}
                  </>
                } on='click' position='left center' trigger={<Label basic content={row.no.length} />} />
            },
            {
                name: 'R No Reply',
                right: true,
                grow: 0.5,
                cell: row => <Button size='tiny' onClick={() => {this.sendNoReply(row.id)}} icon='redo' />
            },
        ]
    }

    componentDidMount() {
      this.handleClear()
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState) {
          let text = prevState.filterText
          let filteredItems = prevState.filteredItems
          if (JSON.stringify(prevState.data) !== JSON.stringify(nextProps.data)) {
            console.log(prevState.data)
            console.log(nextProps.data)
            text = ''
            filteredItems = nextProps.data
          }
          return (
            {
                ...nextProps,
                filterText: text,
                data: [...nextProps.data],
                filteredItems: [...filteredItems]
            }
          )
        }
        return null;
      }

     handleClear = () => {
        this.setFilter('')
      }

      handleFilter = (e) => {
        const val = e.target.value
        if (val === '') {
          this.handleClear()
          return
        }
        this.setFilter(val)
      }

      setFilter = (val) => {
        const {data} = this.state

        let tmpData = [...data]

        let filteredItems
        if (val !== '') {
          filteredItems = tmpData.filter(item =>
            item.properties?.some(_ => _.address.toLowerCase().includes(val.toLowerCase())) ||
            item.borrower?.toLowerCase().includes(val.toLowerCase()) ||
            item.date?.toLowerCase().includes(val.toLowerCase()) ||
            item.dateCreated?.toLowerCase().includes(val.toLowerCase())
          )
        } else {
          filteredItems = tmpData
        }

        this.setState({filterText: val, filteredItems})
      }

    customSort = (rows, field, direction) => {
        const handleField = row => {
        if (row[field]) {
          if (typeof row[field] === 'string') {
             return row[field].toLowerCase();
          }
        }
    
        return row[field];
      };
    
      return _.orderBy(rows, handleField, direction)
    }

    render() {
        const {filteredItems, onRowClicked, filterText, createNewAppraisal, createNewAppraiser} = this.state
        
        return (
            <Datatable
            noHeader={true}
            persistTableHead
            columns={this.columns}
            data={filteredItems}
            defaultSortField='dateCreated'
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight='75vh'
            sortFunction={this.customSort}
            striped
            onRowClicked={onRowClicked}
            responsive
            subHeaderAlign='left'
            subHeader
            subHeaderComponent={(
              <Segment basic style={{width: '100%'}}>
              <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
              {createNewAppraisal}
              {createNewAppraiser}
              </Segment>
            )}
            />
        )
    }
}