import React, { Component } from 'react';
import { Container, Menu, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { getUser, getUserDisplayName, userSignin, userSignout } from '../utility/Auth';
import { ManageProfileURL } from '../utility/Config'
import { UserData } from 'react-oidc';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.state = {
      ...props,
      isLoggedIn: false,
    };
  }

  async componentDidMount() {
    const isLoggedIn = (await getUser())

    this.setState({isLoggedIn})
  }

  render () {
    const { isLoggedIn } = this.state

    return (
      <header style={{marginBottom: '4em'}}>
        <Menu borderless fixed='top'>
          <Container>
            <Menu.Item as={Link} to='/' header>
              Appraisals
            </Menu.Item>
            <Menu.Item position='right' as={Link} to='/'>Dashboard</Menu.Item>
            { isLoggedIn ? (
              <UserData.Consumer>
                {context => (
                  <Dropdown text={ getUserDisplayName(context.user?.profile.name) } className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item as='a' href={ManageProfileURL} target='_blank' >Profile</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => {userSignout()}}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                )}
              </UserData.Consumer>
            ) : (
              <Menu.Item onClick={(e) => {userSignin()}}>Login</Menu.Item>
            )}
          </Container>
        </Menu>
        </header>
    );
  }
}