import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'

export class Appraisal extends Component {

    render() {
        return (
            <Container>

            </Container>
        )
    }
}