import {nameExpression, emailExpression} from './Global'
import { makeUserManager } from 'react-oidc'

const passRoles = ["SuperAdmin", "SuperMember", "AppraisalsAdmin", "AppraisalsMember"]

const adminRoles = ["SuperAdmin", "AppraisalsAdmin"]

const config = {
  authority: process.env.REACT_APP_SSO_AUTHORITY_URL,
  client_id: process.env.REACT_APP_SSO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile roles appraisals-api',
  post_logout_redirect_uri: process.env.REACT_APP_SSO_LOGOUT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_SSO_SILENT_RENEW_URI,
  accessTokenExpiringNotificationTime: 100
}

export const userManager = makeUserManager(config)

export const getUser = async () => {
  const user = await userManager.getUser()
  //console.log(user)
  return user
}

export const getUserDisplayName = (name) => {
  return name === undefined ? null : typeof name === 'string' ? name : name[name.length-1]
}

export const getUserShortDisplayName = (name) => {
  var resultName = ''
  var displayName = typeof name === 'string' ? name : name[name.length-1]
  if (displayName.match(emailExpression)) {
    resultName = displayName[0]
  } else {
    var pieces = displayName.match(nameExpression)
    resultName = `${pieces[0]} ${pieces[pieces.length-1]}`
  }

  return resultName.toUpperCase()
}

var isAdmin = false

export const isUserAdmin = () => {
  return isAdmin
}

export const userSignin = () => {
  userManager.signinRedirect()
}

export const userSignout = () => {
  userManager.signoutRedirect()
}

export const userAuthorized = (role) => {
  var result = false
  if (typeof role === 'string') {
    if (passRoles.includes(role)) {
      result = true
    }
  } else {
    if (passRoles.some(_ => role.indexOf(_) >= 0)) {
      result = true
    }
  }
  isAdmin = userAdmin(role)
  return result
}

export const userAdmin = (role) => {
  var result = false
  if (typeof role === 'string') {
    if (adminRoles.includes(role)) {
      result = true
    }
  } else {
    if (adminRoles.some(_ => role.indexOf(_) >= 0)) {
      result = true
    }
  }
  return result
}