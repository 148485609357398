import React from 'react';

import {Message} from 'semantic-ui-react'

export const RenderLoadingMessage = () => (
    <p><em>Loading...</em></p>
  )
  
  export const RenderErrorMessage = () => {
    return (
      <p><em>Error Loading Content</em></p>
    )
  }
  
  
  export const ErrorMessage = ({message}) => (
      <Message negative>
        <Message.Header>{message}</Message.Header>
      </Message>
    )