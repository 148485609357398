import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import './utility/CSS-Importer'

import { makeAuthenticator, Callback } from 'react-oidc'
import { userManager } from './utility/Auth';
import App from './App'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

const AppWithAuth = makeAuthenticator({
  userManager: userManager,
  signinArgs: {
    state: {
      path: window.location.pathname
    }
  }
})(App);

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route
        path="/callback"
        render={routeProps => (
          <Callback
            onSuccess={user => {
              // `user.state` will reflect the state that was passed in via signinArgs.
              if (user.state) {
                routeProps.history.push(user.state.path)
              } else {
                routeProps.history.push('/')
              }
            }}
            onError={error => {
              console.log(error)
            }}
            userManager={userManager}
          />
        )}
      />
      <AppWithAuth />
      <Redirect to='/' />
      </Switch>
  </BrowserRouter>,
  rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
