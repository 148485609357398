import React from 'react'
import MaskedInput from 'react-text-mask'
import { Form } from 'semantic-ui-react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
}

export const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return <Form.Field>
         <label>{inputProps.label}</label>
        <MaskedInput autoComplete='off' placeholder='$' mask={currencyMask} name={inputProps.name} value={inputProps.value} onChange={inputProps.onChange} onFocus={(e) => e.target.select()} />
      </Form.Field>
}