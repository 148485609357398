import { getUser } from "./Auth"

export const emailExpression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g

export const nameExpression = /\b(\w)/g

let loadingData = false

export async function makePostRequest(url, data = {}) {
    return await makeRequest('POST', url, data)
  }
  
  export async function makePutRequest(url, data = {}) {
    return await makeRequest('PUT', url, data)
  }
  
  export async function makeGetRequest(url) {
    return await makeRequest('GET', url)
  }
  
  export async function makeDeleteRequest(url) {
    return await makeRequest('DELETE', url)
  }
  
  async function makeRequest(method, url, data = {}) {
    if (loadingData) {
      return
    }
    loadingData = true
  

    const options = {
      method: method, // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',//x-www-form-urlencoded',
        'Connection': 'Keep-Alive',
      }
    }

    const token = (await getUser()).access_token

    options.headers['Authorization'] = 'Bearer ' + token
    
    if (method !== 'GET' && method !== 'DELETE') {
      options.body = JSON.stringify(data) // body data type must match "Content-Type" header
    }
  
    console.log(url)
  
    const result = await fetch(url, options)
    .then(res => {
      // console.log(res)
      return res.ok ? res.json() : null
    })
    .then((result) => {
      return result
    }).catch((error) => {
      console.log(error);
      return null
    })
  
    loadingData = false
  
    console.log(result)
  
    return result
  }