import React, { Component } from 'react';

import { RenderLoadingMessage, RenderErrorMessage } from '../utility/GlobalComponents'
import { Container, Modal, Button, Checkbox, Icon, Form, Table, Dimmer, Loader, Segment } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import { DashboardTable } from './tables/dashboard-table'
import { makeGetRequest, makePostRequest } from '../utility/Global'
import { AppraisalsPathURL, AppraisersPathURL } from '../utility/Config'
import { CurrencyInput } from './extras/CurrencyInput'
import SearchLocationInput from './extras/SearchLocationInput';
import { ConvertCurrencyToNumberString } from '../utility/Helper';

const EmailsProcessingLoader = ({isSending, emailComplete}) => (
    <Dimmer className='workaround' active={isSending || emailComplete}>
        <Loader className='workaround' size='huge'>
            {isSending ? (
                'Email In Progress...'
            ) : emailComplete ? (
                'Email Sent Successfully'
            ) : null }
        </Loader>
    </Dimmer>
)

export class Dashboard extends Component {
  static displayName = Dashboard.name;

  constructor(props) {
    super(props);
    this.state = {
        openAppraisal: false,
        openAppraiser: false,
        newAppraisal: {},
        newAppraiser: {},
        newProperties: [],
        data: [],
        appraisersToSend: [],
        appraisers: [],
        isLoading: true,
        isSending: false };
  }

  async componentDidMount() {
    let appraisers = await makeGetRequest(AppraisersPathURL)

    let data = await makeGetRequest(AppraisalsPathURL)

    this.setState({appraisers, data, isLoading: false})
  }

  async createNewAppraisal() {
      const {newAppraisal, newProperties, data, appraisersToSend} = this.state
      
      let filtered = appraisersToSend.filter(_ => _.send).map(_ => {
          delete _.send
          return _
      })

      newProperties.forEach(_ => {
        _.value = ConvertCurrencyToNumberString(_.value)
      })

      this.setState({isSending:true})

      const result = await makePostRequest(AppraisalsPathURL, {
          properties: newProperties,
          appraisal: newAppraisal,
          appraisers: filtered
      })

      if (result != null) {
          data.push(result)

          this.setState({isSending: false, emailComplete: true, data, newAppraisal: {}, newProperties: []})

          setTimeout(() => {
              this.setState({emailComplete: false})
              this.setOpenAppraisal(false)
          }, 3000);
      }
  }

  async resendNoReplies(aid) {
      this.setState({isSending:true})

      let result = await makeGetRequest(`${AppraisalsPathURL}/resendnoreply?appraisalId=${aid}`)

      if (result.level === 1) {
        this.setState({isSending: false, emailComplete: true})

        setTimeout(() => {
          this.setState({emailComplete: false})
        }, 3000);
      } else {
          alert('Error')
      }
  }

  async createNewAppraiser() {
    const {newAppraiser, appraisers} = this.state
    
    const result = await makePostRequest(AppraisersPathURL, newAppraiser)

    if (result != null) {
        appraisers.push(result)

        this.setState({appraisers, newAppraiser: {}})
    }
}

  onRowClicked = (row, event) => {
    //this.props.history.push(`/appraisals/${row.id}`)
  }

  setOpenAppraisal = (openAppraisal) => {
      const {appraisers} = this.state

      const appraisersToSend = appraisers.map(appraiser => {
          appraiser.send = true
          return appraiser
      })

      this.setState({newAppraisal: {}, newProperties: [], openAppraisal, appraisersToSend})
  }

  setOpenAppraiser = (openAppraiser) => {
      this.setState({openAppraiser})
  }

  handlePropertyChange = (e, {name, value} = e.target) => { // Need to use e.target here to play along with the Currency Input
    const {newProperties} = this.state

    const key = name.split('.')[0]
    const index = name.split('.')[1]

    let property = newProperties[index]
    if (!property) {
      property = {}
      newProperties[index] = property
    } 

    property[key] = value
    
    this.setState({ newProperties })
  }

  handleAppraisalChange = (e, {name, value} = e.target) => { // Need to use e.target here to play along with the Currency Input
    const {newAppraisal} = this.state
    newAppraisal[name] = value
    
    this.setState({ newAppraisal })
  }

  handlePropertyAddress = (address,index) => {
    const {newProperties} = this.state

    let property = newProperties[index]
    if (!property) {
      if (address.trim().length === 0) {
        return
      }
      property = {}
      newProperties[index] = property
    } 
    
    property['address'] = address
    
    this.setState({ newProperties })
  }

  handleAppraiserChange = (e, {name, value}) => { 
    const {newAppraiser} = this.state
    
    newAppraiser[name] = value
    
    this.setState({ newAppraiser })
  }

  toggleAppraisers = (e, {checked} = e.target) => {
    const {appraisersToSend} = this.state

    appraisersToSend.forEach(item => {
        item.send = checked
    })

    this.setState({appraisersToSend})
  }

  toggleAppraiser = (e, {checked, name} = e.target) => {
    const {appraisersToSend} = this.state

    const appraiser = appraisersToSend.find(_ => _.id.toString() === name)
    appraiser.send = checked

    this.setState({appraisersToSend})
  }

  renderAppraisalModal() {
        const {openAppraisal, newAppraisal, newProperties, appraisersToSend} = this.state

        let count = newProperties.length + 1
        if (count >= 5) {
          count = 5
        }
      return (
        <Modal
        className='new-appraisal-modal'
        size='small'
        closeIcon={true}
        closeOnDimmerClick={false}
          open={openAppraisal}
          onClose={() => this.setOpenAppraisal(false)}
          onOpen={() => this.setOpenAppraisal(true)}
          trigger={
            <Button floated='right' type='button' content='New Property' positive/>
            }
          >
          <Modal.Header>New Property</Modal.Header>
          <Modal.Content className='properties' scrolling>
          <Form size='tiny'>
              {/*<Form.Input fluid name='propertyAddress' label='Address' placeholder='Address' value={newAppraisal?.propertyAddress ?? ''}  onChange={this.handleAppraisalChange.bind(this)} />*/}
              {
                Array.from(Array(count), (e, i) => {
                  return <Segment key={i}>
                  <SearchLocationInput index={i} name='address' label='Addess' placeholder='Enter an Address' onChange={this.handlePropertyAddress.bind(this)} />
                    <Form.Group widths='equal'>
                      <Form.Input type='number' name={`units.${i}`} label='Number of Units' placeholder='#' value={newProperties[i]?.units ?? ''} onChange={this.handlePropertyChange.bind(this)} />
                      <CurrencyInput name={`value.${i}`} label='Value' value={newProperties[i]?.value ?? ''} onChange={this.handlePropertyChange.bind(this)} />
                      {/*<Form.Input fluid name='date' label='Date' placeholder='2020-01-01' value={newAppraisal?.date ?? ''} onChange={this.handleAppraisalChange.bind(this)} />*/}
                    </Form.Group>
                  </Segment>
                })
              }
            </Form>
          </Modal.Content>
          <Modal.Content>
            <Form size='tiny'>
              <Form.Group widths='equal'>
              <Form.Input name='borrower' label='Borrower' placeholder='Borrower Name' value={newAppraisal?.borrower ?? ''} onChange={this.handleAppraisalChange.bind(this)} />
              <DateInput
                      name="date"
                      label='Date'
                      placeholder="Date"
                      dateFormat='YYYY-MM-DD'
                      value={newAppraisal?.date ?? ''}
                      minDate={new Date()}
                      iconPosition="left"
                      clearable
                      closable
                      animation='fade'
                      clearIcon={<Icon name="remove" color="red" />}
                      onChange={this.handleAppraisalChange.bind(this)}
                      />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Content scrolling>
            <Modal.Description>
            <Table celled striped compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'><Checkbox checked={appraisersToSend?.every(_ => _.send)} indeterminate={!appraisersToSend?.every(_ => _.send) && appraisersToSend?.some(_ => _.send)} onChange={this.toggleAppraisers}/></Table.HeaderCell>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {appraisersToSend?.map((appraiser, index) => (
                            <Table.Row key={index}>
                                <Table.Cell textAlign='center'>
                                    <Checkbox checked={appraiser?.send} name={appraiser?.id.toString()} onChange={this.toggleAppraiser}/>
                                </Table.Cell>
                                <Table.Cell>
                                    {appraiser?.firstName}
                                </Table.Cell>
                                <Table.Cell>
                                    {appraiser?.lastName}
                                </Table.Cell>
                                <Table.Cell>
                                    {appraiser?.email}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.createNewAppraisal()} primary>
              Send <Icon name='chevron right' />
            </Button>
          </Modal.Actions>
        </Modal>
      )
  }

   renderAppraiserModal() {
        const {openAppraiser, newAppraiser, appraisers} = this.state

      return (
        <Modal
        className='new-appraiser-modal'
        size='large'
          open={openAppraiser}
          onClose={() => this.setOpenAppraiser(false)}
          onOpen={() => this.setOpenAppraiser(true)}
          trigger={
            <Button floated='right' type='button' content='Appraisers' positive/>
            }
        >
          <Modal.Header>Appraisers</Modal.Header>
          <Modal.Content>
          <Form size='tiny'>
              <Form.Group widths='equal'>
                <Form.Input fluid name='firstName' label='First Name' placeholder='First Name' value={newAppraiser?.firstName ?? ''} onChange={this.handleAppraiserChange.bind(this)} />
                <Form.Input fluid name='lastName' label='Last Name' placeholder='Last Name' value={newAppraiser?.lastName ?? ''} onChange={this.handleAppraiserChange.bind(this)} />
              </Form.Group>
              <Form.Input fluid name='email' label='Email' placeholder='johnsmith@gmail.com' value={newAppraiser?.email ?? ''} onChange={this.handleAppraiserChange.bind(this)} />
            </Form>
          </Modal.Content>
          <Modal.Content scrolling>
            <Modal.Description>
                <Table celled striped compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {appraisers?.map((appraiser, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {appraiser?.firstName}
                                </Table.Cell>
                                <Table.Cell>
                                    {appraiser?.lastName}
                                </Table.Cell>
                                <Table.Cell>
                                    {appraiser?.email}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.createNewAppraiser()} primary>
              Create <Icon name='chevron right' />
            </Button>
          </Modal.Actions>
        </Modal>
      )
  }

  renderDashboard() {
    const {data} = this.state
    
    return (
        <DashboardTable data={data} onRowClicked={this.onRowClicked.bind(this)} createNewAppraisal={this.renderAppraisalModal()} createNewAppraiser={this.renderAppraiserModal()} sendNoReply={this.resendNoReplies.bind(this)}/>
    )
  }

  render() {
    const {data, isLoading, isSending, emailComplete} = this.state
    let contents = isLoading ? RenderLoadingMessage() : data === null ? RenderErrorMessage() : this.renderDashboard()

    return (
      <div className='Dashboard'>
        <Container fluid style={{padding: '0 15em'}}>
          <EmailsProcessingLoader isSending={isSending} emailComplete={emailComplete} />

            {contents}
        </Container>
      </div>
    );
  }
}

