export function formatNumber(amount) {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: (amount % 1) > 0.0 ? 2 : 0
    });
    
    return formatter.format(amount);
  };

export function ConvertCurrencyToNumberString(amount) {
if ( amount === null || (typeof amount === 'string' && amount.length === 0)) return 0
if ( typeof amount !== 'string' ) return amount
return amount.replace(/[^0-9.-]+/g,"")
}