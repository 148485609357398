import React, { Component } from 'react';
import { Route } from 'react-router';
import { Dashboard } from './components/Dashboard'
import { Appraisal } from './components/Appraisal'
import { getUser, userAuthorized } from './utility/Auth';
import { Layout } from './components/Layout';

const routes = [
    {
      path: '/',
      component: Dashboard
    }, {
      path: '/Appraisals/:id',
      component: Appraisal,
    }
  ];
    

export default class App extends Component {
    static displayName = App.name;
  
    constructor(props) {
      super(props);
      this.state = {
        isAuthorized: true
      };
    }
  
    async componentDidMount() {
      const user = await getUser()
  
      if (user && userAuthorized(user.profile.role)) {
        
      } else {
        this.setState({isAuthorized: false})
      }
    }
  
    render () {
      const routeComponents = routes.map(({path, component}, key) => <Route exact path={path} component={component} key={key}/>);
      const {isAuthorized} = this.state
      return (
        <Layout>
          {isAuthorized ? (
            routeComponents) : <em>Access Denied</em>}
        </Layout>
      );
    }
  }